import React, {Suspense, useEffect, useState} from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ApexChart from 'components/Charts';
import Spinner from 'components/Spinner';
import { formatNumberToCurrency, numberWithCommas } from "helpers/utils";
import useLocalStorage from 'hooks/useLocalStorage';
import useRouter from 'hooks/useRouter';
import useUrlParams from 'hooks/useUrlParams';
import {useDispatch, useSelector} from 'react-redux';
import {useRouteMatch} from 'react-router-dom';
import * as actions from 'store/actions';
import {config} from 'views/HomeScreen/chartConfig';

import useStyles from './styles';
import TableActualsForecasts from './TableActualsForecasts';
import TableSummary from './TableSummary';
import {FormControl, Grid2, InputLabel, MenuItem, Select, Stack, ToggleButton, ToggleButtonGroup} from "@mui/material";
import ReportsSideMenu from "./ReportSideMenu";
import TextWithPadding from '../../../styles/textWithPadding';
import { styled } from "@mui/material/styles";

const StyledToggleButton = styled(ToggleButton)({
    "&.Mui-selected, &.Mui-selected:hover": {
        color: "white",
        backgroundColor: '#3379FF'
    }
});

const namesMapper = {
    BaseRent: 'Base Rent',
    TurnoverRent: 'Turnover Rent',
    ServiceCharge: 'Service Charge',
    OtherIncome: 'Other Income',
    CumulativeRent: 'Cumulative Rent',
    NOI: 'NOI',
    Opex: 'Opex',
    Capex: 'Capex',
    OtherExpenses: 'Other Expenses',
    LeasingFee: 'Leasing Fee',
    FitOut: 'Fit Out',
    MLA: 'MLA'
};

const ChartDetails = () => {
    const year = useSelector((state) => state.home.yearFilter);
    const {data, error, loaded} = useSelector((state) => state.home.chartDataPage);
    const dispatch = useDispatch();
    const classes = useStyles();
    const {portfolioIds} = useUrlParams();
    const {data: assets} = useSelector((state) => state.assets.assets);
    const {
        data: summaryData,
        loading: summaryDataLoading,
        loaded: summaryDataLoaded
    } = useSelector((state) => state.home.summaryData);
    const {data: forecastsData} = useSelector((state) => state.home.forecasts);
    const {data: actualsData} = useSelector((state) => state.home.actuals);
    const {storedValue, setValue} = useLocalStorage('assetId', '');
    const {
        params: {tab, assetId: id}
    } = useRouteMatch();
    const [assetId, setAssetId] = useState(id || storedValue || assets[0]?.id);
    const router = useRouter();
    const queryParam = new URLSearchParams(router.location.search);
    const [fromValue, setFromValue] = useState('');
    const [locationSearch, setLocationSearch] = useState('');
    const [period, setPeriod] = React.useState('month');

    let defaultOptions = {...config.options}
    if (name === 'Tenants' || name === 'Occupancy') {
        defaultOptions = {
            ...defaultOptions,
            yaxis: {
                ...defaultOptions.yaxis,
                labels: {
                    formatter: (value) => `${numberWithCommas(value)}`
                }
            }
        };
    }
    const [options, setOptions] = useState(defaultOptions);
    useEffect(() => {
        if (period === 'year') {
            setOptions(prevOptions => ({
                ...prevOptions,
                xaxis: {
                    ...prevOptions.xaxis,
                    categories: new Array(5).fill().map((_, i) => year + i)
                }
            }));

        } else {
            setOptions(prevOptions => ({
                ...prevOptions,
                xaxis: {
                    ...prevOptions.xaxis,
                    categories: [
                        'Jan',
                        'Feb',
                        'Mar',
                        'Apr',
                        'May',
                        'Jun',
                        'Jul',
                        'Aug',
                        'Sep',
                        'Oct',
                        'Nov',
                        'Dec'
                    ]
                }
            }));
        }
    }, [period, year]);

    useEffect(() => {
        if (queryParam.get('from')) {
            setFromValue(queryParam.get('from'));
        }
    }, [fromValue]);

    useEffect(() => {
        if (router.location.search) {
            setLocationSearch(router.location.search);
        }
    }, [locationSearch]);

    useEffect(() => {
        if (!data || !id || id === assetId) return;

        setValue(id);
    }, [assetId]);

    useEffect(() => {
        const annualData = period === 'year' ? {year: year, length: 5} : null
        const yearFilter = period === 'month' ? [year] : new Array(5).fill().map((_, i) => year + i)

        dispatch(actions.getFilterYears());
        dispatch(
            actions.getChartDataPerPage(tab, {PortfolioID: [], AssetID: [assetId], Year: yearFilter}, period, annualData)
        );
        dispatch(
            actions.getSummaryTableData(tab, {PortfolioID: [], AssetID: [assetId], Year: yearFilter}, period, annualData)
        );
        dispatch(actions.getTenantsActuals(tab, {PortfolioID: [], AssetID: [assetId], Year: yearFilter}, period, annualData));
        dispatch(
            actions.getTenantsForecasts(tab, {PortfolioID: [], AssetID: [assetId], Year: yearFilter}, period, annualData)
        );
    }, [assetId, tab, year, period]);

    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "scroll"
        };
    }, []);

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        dispatch(actions.getAssets(portfolioIds));
    }, []);

    const handleSelectAsset = (e) => {
        setAssetId(e.target.value);
        setValue(e.target.value);
        const search = queryParam.get('from') ? `?from=${queryParam.get('from')}` : '';
        router.push(`/${tab}/Details/${e.target.value}${search}`);
    };

    const handleSelectYear = (event, value) => {
        dispatch(actions.setYearFilter(value));
    };

    if (error && loaded) {
        return (
            <Typography color="error" style={{marginLeft: 30, marginTop: 30}}>
                {error}
            </Typography>
        );
    }

    return (
        (
            <Grid2 container spacing={5} wrap="wrap" sx={{padding: '40px'}}>
                <Grid2 size={{lg: 3, xl: 2}}>
                    <Stack spacing={2}>
                        <Paper elevation={3} sx={{padding: '20px'}}>
                            <Stack spacing={2}>
                                <FormControl size="small">
                                    <InputLabel>Asset</InputLabel>
                                    <Select
                                        label="Assets"
                                        keyword="name"
                                        value={assetId}
                                        onChange={handleSelectAsset}
                                        IconComponent={null}
                                        autoWidth
                                    >
                                        {assets.map((asset) => (
                                            <MenuItem value={asset.id}>{asset.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <ToggleButtonGroup
                                    color="primary"
                                    value={year}
                                    exclusive
                                    onChange={handleSelectYear}
                                    fullWidth
                                >
                                    {new Array(3).fill().map((_, i) => {
                                        return new Date().getFullYear() - 2 + i
                                    }).map((item) => (
                                        <ToggleButton value={item}>{item}</ToggleButton>
                                    ))}
                                </ToggleButtonGroup>
                                <ToggleButtonGroup
                                  color="primary"
                                  value={period}
                                  exclusive
                                  onChange={(_, value) => setPeriod(value)}
                                  aria-label="Platform"
                                  sx={{
                                      alignItems: 'center',
                                      display: 'flex',
                                  }}
                                >
                                    <StyledToggleButton value="month">Monthly</StyledToggleButton>
                                    <StyledToggleButton value="year">Annual</StyledToggleButton>
                                </ToggleButtonGroup>
                            </Stack>
                        </Paper>
                        <ReportsSideMenu assetId={assetId} classes={classes} tab={tab}/>
                    </Stack>
                </Grid2>
                <Grid2
                    sx={{overflowY: 'scroll', height: 'calc(100vh - 120px)', overflowX: 'hidden'}}
                    size="grow">
                    <Stack spacing={2}>
                        <Paper>
                            <Box style={{padding: '20px', overflowX: 'hidden'}}>
                                <Box>
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Typography variant="h4">{namesMapper[tab]}</Typography>
                                    </Box>

                                    {tab !== 'MLA' && <Box className={classes.wrapCard}>
                                        <Typography variant="h6">
                                            {`Actuals ${year}: ${formatNumberToCurrency(
                                                data?.actualforecastsList?.reduce((acc, cur) => acc + cur, 0)
                                            )}`}
                                        </Typography>
                                    </Box>}
                                    <Box className={classes.wrapCard}>
                                        <Typography variant="h6">
                                            {`Total Forecasted ${year}: ${formatNumberToCurrency(
                                                data?.forecastsList?.reduce((acc, cur) => acc + cur, 0)
                                            )}`}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box>
                                    <Suspense fallback={<Spinner mini/>}>
                                        <ApexChart
                                            height={350}
                                            options={options}
                                            series={[
                                                {
                                                    name: `${namesMapper[tab]} Forecasts`,
                                                    data: tab !== 'MLA' ? summaryData?.totalforecastsList : summaryData?.mlasList
                                                },
                                                {
                                                    name: `${namesMapper[tab]} Actuals`,
                                                    data: data?.actualforecastsList
                                                }
                                            ]}
                                        />
                                    </Suspense>
                                </Box>
                            </Box>
                            {summaryData && (
                                <TableSummary
                                    data={summaryData || null}
                                    loading={summaryDataLoading}
                                    loaded={summaryDataLoaded}
                                    tab={tab}
                                    period={period}
                                    year={year}
                                />
                            )}
                        </Paper>
                        <Paper>
                            <Box>
                                <TextWithPadding>Forecasts</TextWithPadding>
                            </Box>
                            <Box>
                                <TableActualsForecasts
                                    data={forecastsData || []}
                                    name="forecastsList"
                                    tab={tab}
                                    assetId={assetId}
                                    period={period}
                                    year={year}
                                />
                            </Box>
                        </Paper>
                        {tab !== 'MLA' && (<Paper>
                            <Box>
                                <TextWithPadding>Actuals</TextWithPadding>
                            </Box>
                            <Box>
                                <TableActualsForecasts
                                    data={actualsData || []}
                                    name="actualsList"
                                    tab={tab}
                                    assetId={assetId}
                                    period={period}
                                    year={year}
                                />
                            </Box>
                        </Paper>)}
                    </Stack>
                </Grid2>
            </Grid2>
        )
    );
};
export default ChartDetails;
